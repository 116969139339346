import React from 'react';
import MenuItemIcon from 'components/commons/MenuItemIcon/MenuItemIcon';
import sourceFileIcon from '../../../../../../../../assets/images/icons/green-file.png';
import copyDocumentIcon from '../../../../../../../../assets/images/icons/icons8-copy-nice.png';
import blockchainLogo from '../../../../../../../../assets/images/blockchain.png';

type SourceDocumentListProps = {
  documents: {
    sourceDocumentTxHash: string | null;
    sourceDocumentHash: string | null;
    sourceDocumentFileName: string | null;
  }[];
  onDownloadFile: (fileName: string) => void;
  onCopyHash: (hash: string) => void;
  onViewTransaction: (txHash: string) => void;
};

const SourceDocumentBlockchainProofsList: React.FC<SourceDocumentListProps> = ({
  documents,
  onDownloadFile,
  onCopyHash,
  onViewTransaction,
}) => {
  return (
    <>
      {documents.length > 0 &&
        documents.map(
          (
            {
              sourceDocumentTxHash,
              sourceDocumentHash,
              sourceDocumentFileName,
            },
            index
          ) => (
            <React.Fragment key={index}>
              {sourceDocumentFileName && (
                <MenuItemIcon
                  key={`sourceDocumentFileName-${index}`}
                  icon={sourceFileIcon}
                  onClick={() => onDownloadFile(sourceDocumentFileName)}
                  title={`Document ${documents.length > 1 ? index + 1 : ''}`}
                />
              )}
              {sourceDocumentHash && (
                <MenuItemIcon
                  key={`sourceDocumentHash-${index}`}
                  icon={copyDocumentIcon}
                  onClick={() => onCopyHash(sourceDocumentHash)}
                  title={`Document hash ${
                    documents.length > 1 ? index + 1 : ''
                  }`}
                />
              )}
              {sourceDocumentTxHash && (
                <MenuItemIcon
                  key={`sourceDocumentTxHash-${index}`}
                  icon={blockchainLogo}
                  onClick={() => onViewTransaction(sourceDocumentTxHash)}
                  title={`Document transaction ${
                    documents.length > 1 ? index + 1 : ''
                  }`}
                />
              )}
            </React.Fragment>
          )
        )}
    </>
  );
};

export default SourceDocumentBlockchainProofsList;
