export const StandardTypes = [
  {
    id: 'trustwell demo',
    category: 'gas',
  },
  {
    id: 'trustwell',
    category: 'gas',
  },
  {
    id: 'trustwell_transport',
    category: 'gas',
  },
  {
    id: 'miq',
    category: 'gas',
  },
  {
    id: 'default-eu',
    category: 'gas',
  },
  {
    id: 'NG+',
    category: 'gas',
  },
  {
    id: 'LNG',
    category: 'gas',
  },
  {
    id: 'EU NAT GAS',
    category: 'gas',
  },
  {
    id: 'eu-natural-gas-origin',
    category: 'gas',
  },
  {
    id: 'SAF',
    category: 'gas',
  },
  {
    id: 'CCUS',
    category: 'gas',
  },
  {
    id: 'go_power',
    category: 'gas',
  },
  {
    id: 'mining',
    category: 'platinum',
  },
  {
    id: 'recycling',
    category: 'platinum',
  },
  {
    id: 'Anglo American',
    category: 'platinum',
  },
  {
    id: 'Umicore',
    category: 'platinum',
  },
  {
    id: 'Mining company 1',
    category: 'platinum',
  },
  {
    id: 'Recycling company 1',
    category: 'platinum',
  },
  {
    id: 'Tolling company 1',
    category: 'platinum',
  },
  {
    id: 'Mining Company 1 – Tolling Company 1',
    category: 'platinum',
  },
];
