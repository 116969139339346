import React, { useState, useCallback, useMemo } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Tooltip, IconButton, Menu } from '@mui/material';
import { apiGet, openErrorSnack, openSuccessSnack } from 'actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { blockchainExplorerUrl } from 'config/blockchainExplorerConfig';
import downloadFileWithCookie from 'utils/fetch/downloadFile';
import DataPreviewModal from './Components/DataPreviewModal';
import DataBlockchainProofsList from './Components/DataBlockchainProofsList';
import SourceDocumentBlockchainProofsList from './Components/SourceDocumentBlockchainProofsList';
import MenuItemIcon from 'components/commons/MenuItemIcon/MenuItemIcon';
import errorIcon from '../../../../../../../assets/images/icons/error-80.png';

const BlockchainProofsMenu = React.memo((props: any): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const { row } = props;

  const { dynamicDataHistory = null, aggregated_asset_metering_id_array = [] } =
    row || {};
  const { goldenSourceDynamicDataHash, period, txHash } = useMemo(
    () => dynamicDataHistory || {},
    [dynamicDataHistory]
  );

  const aggregatedMeteringDetails = useMemo(() => {
    return (
      (aggregated_asset_metering_id_array as any[])?.map((item: any) => ({
        sourceDocumentTxHash: item.txHash || null,
        sourceDocumentHash: item.dataHash || null,
        sourceDocumentFileName: item.fileName || null,
      })) || []
    );
  }, [aggregated_asset_metering_id_array]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jsonData, setJsonData] = useState<any>(null);
  const { currentProduct } = useProductSlicesCombiner();

  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCopyDataHash = useCallback(() => {
    if (goldenSourceDynamicDataHash) {
      navigator.clipboard.writeText(goldenSourceDynamicDataHash);
      dispatch(openSuccessSnack('Data hash copied to clipboard.'));
    }
  }, [goldenSourceDynamicDataHash]);

  const handleCopyDocumentHash = useCallback((hash: string) => {
    if (hash) {
      navigator.clipboard.writeText(hash);
      dispatch(openSuccessSnack('Document hash copied to clipboard.'));
    }
  }, []);

  const handlePreviewSourceData = useCallback(async () => {
    if (!goldenSourceDynamicDataHash) {
      dispatch(openSuccessSnack('Source data preview not available.'));
      return;
    }

    setModalOpen(true);
    setLoading(true);

    try {
      const response = await apiGet(
        `/api/fields/s3/${currentProduct?.productId}/${period}/${goldenSourceDynamicDataHash}`
      );
      if (response.ok) {
        const data = await response.json();
        setJsonData(data.data);
      } else {
        throw new Error('Failed to fetch source data');
      }
    } catch (error) {
      console.error('Error fetching source data:', error);
      setJsonData({ error: 'No blockchain proof was found' });
    } finally {
      setLoading(false);
    }
  }, [goldenSourceDynamicDataHash, currentProduct?.productId, period]);

  const handleLinkToTx = useCallback(() => {
    if (txHash) {
      window.open(`${blockchainExplorerUrl}/tx/${txHash}`, '_blank');
    } else {
      dispatch(
        openErrorSnack('Data blockchain transaction link is not available.')
      );
    }
  }, [txHash]);

  const handleSourceDocumentLinkToTx = useCallback((txHash: string) => {
    if (txHash) {
      window.open(`${blockchainExplorerUrl}/tx/${txHash}`, '_blank');
    } else {
      dispatch(
        openErrorSnack('Document blockchain transaction link is not available.')
      );
    }
  }, []);

  const handleDownloadSourceDocument = useCallback((fileName: string) => {
    if (fileName) {
      downloadFileWithCookie(fileName);
    } else {
      dispatch(openErrorSnack('Source document not available.'));
    }
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setJsonData(null);
  }, []);

  return (
    <>
      <Tooltip title="Click to see more actions" placement="bottom">
        <IconButton aria-label="open more menu" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>

      <Menu
        id="blockchain-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        {!period &&
          !goldenSourceDynamicDataHash &&
          !txHash &&
          (aggregatedMeteringDetails.length === 0 ||
            (aggregatedMeteringDetails.length === 1 &&
              !aggregatedMeteringDetails[0].sourceDocumentFileName &&
              !aggregatedMeteringDetails[0].sourceDocumentHash &&
              !aggregatedMeteringDetails[0].sourceDocumentTxHash)) && (
            <MenuItemIcon
              key="noData"
              onClick={() => console.log('No data available')}
              title="No data available"
              icon={errorIcon}
            />
          )}
        <DataBlockchainProofsList
          period={period}
          goldenSourceDynamicDataHash={goldenSourceDynamicDataHash}
          txHash={txHash}
          onPreviewData={handlePreviewSourceData}
          onCopyDataHash={handleCopyDataHash}
          onViewTransaction={handleLinkToTx}
        />

        <SourceDocumentBlockchainProofsList
          documents={aggregatedMeteringDetails}
          onDownloadFile={handleDownloadSourceDocument}
          onCopyHash={handleCopyDocumentHash}
          onViewTransaction={handleSourceDocumentLinkToTx}
        />
      </Menu>

      <DataPreviewModal
        open={modalOpen}
        loading={loading}
        data={jsonData}
        onClose={handleCloseModal}
      />
    </>
  );
});

export default BlockchainProofsMenu;

BlockchainProofsMenu.displayName = 'BlockchainProofs';
